<template>
  <div class="awesomplete">
    <input
      :type="type"
      autocomplete="off"
      aria-autocomplete="list"
      :name="inputName"
      :class="inputClass"
      :placeholder="placeholder"
      v-model.trim="q"
      @focus="open = true"
      v-on:keydown.up.prevent="move(-1)"
      v-on:keydown.down.prevent="move(1)"
      v-on:keydown.enter.prevent="select(selectedItem)"
      v-on:keydown.esc.prevent="reset()"
    />

    <ul v-if="open">
      <li
        v-for="(item, index) in filteredItems"
        :key="index"
        @click.prevent.stop="select(item)"
        :aria-selected="item === selectedItem"
      >
        <slot
          name="item"
          :item="item"
          :html="formatItem(item, query)"
        >
          <span
            v-html="formatItem(item, query)"
          />
        </slot>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    type: { type: String, default: 'text' },
    formId: String,
    initialValue: String,
    inputName: String,
    inputClass: String,
    items: {
      type: Array,
      default () {
        return [];
      }
    },
    placeholder: String
  },
  data () {
    const that = this;
    return {
      open: true,
      q: that.initialValue || '',
      selectedItem: undefined
    };
  },
  watch: {
    filteredItems (items) {
      this.open = true;
      this.selectedItem = items[0];
      this.$emit('matches', this.filteredItems);
    }
  },
  computed: {
    currentIndex () {
      const that = this;
      return that.filteredItems.indexOf(that.selectedItem);
    },
    form () {
      const that = this;
      return document.getElementById(that.formId);
    },
    filteredItems () {
      const that = this;
      if (!that.query)
        return [];

      return that.items.filter(function (item) {
        return that.textContent(item).toLowerCase().indexOf(that.query) !== -1;
      });
    },
    query () {
      const that = this;
      return that.q.toLowerCase();
    }
  },
  methods: {
    highlight (str, q) {
      return str.replace(q, function (a) {
        return `<mark>${a}</mark>`;
      });
    },
    formatItem (item, q) {
      const that = this;
      return that.highlight(that.textContent(item), q);
    },
    move (delta) {
      const that = this;
      const index = that.currentIndex;
      const newIndex = index + delta;
      if (
        !that.filteredItems.length ||
          newIndex < 0 ||
          newIndex >= that.filteredItems.length
      )
        return;

      that.selectedItem = that.filteredItems[newIndex];
    },
    reset () {
      const that = this;
      that.q = '';
    },

    delayedClose () {
      this.$nextTick(() => {
        if (!this.selectedItem) this.open = false;
      });
    },

    async select (item) {
      if (item === undefined || item === null)
        return;

      this.q = this.textContent(item);

      if (this.form) {
        if (item.url)
          this.form.action = item.url;

        // Wait for this.q to populate the input
        this.$nextTick(() => {
          this.form.submit();
        });
      }

      this.$emit('select', item);
      await this.$nextTick();
      this.open = false;
    },

    textContent (item) {
      return typeof item === 'object' ? item.label : item;
    },

    detectFocus ({ target }) {
      if (!(this.$el === target || this.$el.contains(target))) this.open = false;
    }
  },

  mounted () {
    document.addEventListener('click', this.detectFocus);
  },

  unmounted () {
    document.removeEventListener('click', this.detectFocus);
  }

};
</script>
